import { render, staticRenderFns } from "./NoktaMarket.vue?vue&type=template&id=5c307d07&scoped=true&"
import script from "./NoktaMarket.vue?vue&type=script&lang=js&"
export * from "./NoktaMarket.vue?vue&type=script&lang=js&"
import style0 from "./NoktaMarket.vue?vue&type=style&index=0&id=5c307d07&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c307d07",
  null
  
)

export default component.exports