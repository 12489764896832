<template>
  <div class="pb-12" style="text-align: -webkit-center">
    <!-- <div class="m-16" style="position: relative">
      <div
        class="tabla"
        :style="
          'width:' +
          plateWidth * multiplier +
          'px;' +
          'height: ' +
          plateHeight * multiplier +
          'px;'
        "
      ></div>
      <div
        class="plates-block"
        :style="
          'top:' +
          (product.y - product.sizeY / 2) * multiplier +
          'px;' +
          'left:' +
          (product.x - product.sizeX / 2) * multiplier +
          'px;' +
          'width:' +
          product.sizeX * multiplier +
          'px;' +
          'height:' +
          product.sizeY * multiplier +
          'px;' +
          'transform: rotate(' +
          product.rotation +
          'deg);' +
          'background-color: #' +
          RandomColor() +
          ';'
        "
        style=""
        v-for="product in FAKEPRODUCTS"
        :key="product.id"
      ></div>
      <p
        :style="
          'top:' +
          (product.y - product.sizeY / 2) * multiplier +
          'px;' +
          'left:' +
          (product.x - product.sizeX / 2) * multiplier +
          'px;' +
          'width:' +
          product.sizeX * multiplier +
          'px;' +
          'height:' +
          product.sizeY * multiplier +
          'px;' +
          'transform: rotate(' +
          product.rotation +
          'deg);'
        "
        class="title-block"
        v-for="product in FAKEPRODUCTS"
        :key="product.title + product.id"
        style="opacity: 1 !important; z-index: 5"
      >
        {{ product.title.slice(0, -4) }}
      </p>
    </div> -->

    <!-- top bar -->
    <div class="p-4">
      <div class="customContainer flex justify-between">
        <img src="@/assets/images/logo/logo_novafab.png" alt="" />
        <vs-button
          v-if="!isAuthenticated"
          color="dark"
          type="border"
          @click.native="GoLogin()"
        >
          Login
        </vs-button>
        <vs-button v-else color="dark" type="border" @click.native="GoStore()">
          Store
        </vs-button>
      </div>
    </div>

    <!-- swipper -->
    <div>
      <swiper
        :options="swiperOption"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :key="$vs.rtl"
      >
        <swiper-slide
          style="position: relative"
          v-for="image in swipperImagesTop"
          :key="image"
        >
          <img class="Image" :src="image" alt="banner" />
          <vs-button
            class="md:block hidden"
            style="
              right: 10%;
              bottom: 15%;
              position: absolute;
              border-radius: 3px;
            "
            @click="GoStore()"
          >
            SHOPPING NOW!
          </vs-button>
        </swiper-slide>
      </swiper>
    </div>

    <!-- <div class="flex justify-center p-4">
      <vue-typer
        class="vueTyperClass"
        :text="descTitles"
        :repeat="Infinity"
        :shuffle="false"
        initial-action="erasing"
        :pre-type-delay="70"
        :type-delay="70"
        :pre-erase-delay="2000"
        :erase-delay="50"
        erase-style="backspace"
        :erase-on-complete="false"
        caret-animation="blink"
      ></vue-typer>
    </div> -->

    <!-- Banners -->
    <div class="flex justify-center mt-6">
      <div class="vx-row w-full customContainer text-center">
        <div class="vx-col lg:w-1/3 md:w-1/2 w-full mx-auto">
          <img
            style="cursor: pointer"
            @click="OpenCollection(1)"
            src="@/assets/images/nokta_dashboard/2-(1).jpg"
            alt=""
          />
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/2 w-full mx-auto">
          <img
            style="cursor: pointer"
            @click="OpenCollection(2)"
            src="@/assets/images/nokta_dashboard/2-(2).jpg"
            alt=""
          />
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/2 w-full mx-auto">
          <img
            style="cursor: pointer"
            @click="OpenCollection(3)"
            src="@/assets/images/nokta_dashboard/2-(3).jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- Collections -->
    <div class="flex justify-center mt-12">
      <div class="custonContainer">
        <div class="allCollectionsTitle">All Collections</div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="customContainer">
        <div class="vx-row flex justify-center p-4">
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(1).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(2).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(3).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(4).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(5).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(6).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(7).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(8).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(9).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(10).jpg" alt="" />
          </div>
          <div class="vx-col mx-auto xl:w-1/4 flex justify-center mb-4">
            <img src="@/assets/images/nokta_dashboard/3-(11).jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- swipper 2 -->
    <div>
      <swiper
        :options="swiperOption"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :key="$vs.rtl"
      >
        <swiper-slide
          style="position: relative"
          v-for="image in swipperImagesBottom"
          :key="image"
        >
          <img class="Image" :src="image" alt="banner" />
          <vs-button
            style="
              right: 10%;
              bottom: 15%;
              position: absolute;
              border-radius: 3px;
            "
            @click="GoStore()"
          >
            SHOPPING NOW!
          </vs-button>
        </swiper-slide>
      </swiper>
    </div>

    <!-- Do Not Miss -->
    <div class="flex justify-center mt-12">
      <div class="customContainer flex justify-center">
        <div class="allCollectionsTitle">Do Not Miss</div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="customContainer">
        <swiper class="swiper" :options="swiperOption2">
          <swiper-slide v-for="(image, index) in dontMissImages" :key="image">
            <img
              style="cursor: pointer"
              @click="OpenDontMiss(index)"
              class="Image pl-4 pr-4"
              :src="image"
              alt=""
            />
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import { VueTyper } from "vue-typer";

export default {
  data() {
    return {
      // multiplier: 5,
      // plateWidth: 133,
      // plateHeight: 75,
      // FAKEPRODUCTS: [
      //   {
      //     id: 1,
      //     title: "FSHN01-N-4.stl",
      //     x: 10,
      //     y: 35,
      //     rotation: 270,
      //     sizeX: 69.44637,
      //     sizeY: 18.002737,
      //   },
      //   {
      //     id: 2,
      //     title: "FSHN01-N-5.stl",
      //     x: 24,
      //     y: 29.5,
      //     rotation: 270,
      //     sizeX: 54.159546,
      //     sizeY: 12.363223,
      //   },
      //   {
      //     id: 3,
      //     title: "FSHN01-N-3.stl",
      //     x: 35.5,
      //     y: 34,
      //     rotation: 270,
      //     sizeX: 54.1596,
      //     sizeY: 12.3632,
      //   },
      //   {
      //     id: 4,
      //     title: "FSHN01-R-58.stl",
      //     x: 49.5,
      //     y: 14.5,
      //     rotation: 270,
      //     sizeX: 27.602001,
      //     sizeY: 18.2094,
      //   },
      //   {
      //     id: 5,
      //     title: "FSHN01-B.stl",
      //     x: 31.5,
      //     y: 67.5,
      //     rotation: 0,
      //     sizeX: 39.9803,
      //     sizeY: 11.648377,
      //   },
      //   {
      //     id: 6,
      //     title: "FSHN01-N-6.stl",
      //     x: 48.5,
      //     y: 45,
      //     rotation: 270,
      //     sizeX: 30.407917,
      //     sizeY: 11.800117,
      //   },
      //   {
      //     id: 7,
      //     title: "FSHN01-N-2.stl",
      //     x: 59.5,
      //     y: 34.5,
      //     rotation: 270,
      //     sizeX: 30.407898,
      //     sizeY: 11.8001,
      //   },
      //   {
      //     id: 8,
      //     title: "FSHN01-E-2.stl",
      //     x: 60.5,
      //     y: 63.5,
      //     rotation: 270,
      //     sizeX: 12.088,
      //     sizeY: 29.357,
      //   },
      //   {
      //     id: 9,
      //     title: "FSHN01-E-1.stl",
      //     x: 67.5,
      //     y: 15,
      //     rotation: 0,
      //     sizeX: 12.088,
      //     sizeY: 29.357,
      //   },
      //   {
      //     id: 10,
      //     title: "FSHN01-N-7.stl",
      //     x: 66,
      //     y: 52.5,
      //     rotation: 270,
      //     sizeX: 7.618768,
      //     sizeY: 16.237598,
      //   },
      //   {
      //     id: 11,
      //     title: "FSHN01-N-1.stl",
      //     x: 69.5,
      //     y: 39.5,
      //     rotation: 0,
      //     sizeX: 7.6187,
      //     sizeY: 16.2376,
      //   },
      // ],
      descTitles: [
        "Easy 3 steps!",
        "Choose",
        "Print",
        "Sell",
        "Nokta Model & NovaFab",
      ],
      swipperImagesTop: [
        require("@/assets/images/nokta_dashboard/1-(1).jpg"),
        require("@/assets/images/nokta_dashboard/1-(2).jpg"),
        require("@/assets/images/nokta_dashboard/1-(3).jpg"),
        require("@/assets/images/nokta_dashboard/1-(4).jpg"),
      ],
      swipperImagesBottom: [
        require("@/assets/images/nokta_dashboard/1-(5).jpg"),
        require("@/assets/images/nokta_dashboard/1-(6).jpg"),
        require("@/assets/images/nokta_dashboard/1-(7).jpg"),
        require("@/assets/images/nokta_dashboard/1-(8).jpg"),
      ],
      swiperOption2: {
        slidesPerView: 5,
        spaceBetween: 10,
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      dontMissImages: [
        require("@/assets/images/nokta_dashboard/4-(1).jpg"),
        require("@/assets/images/nokta_dashboard/4-(2).jpg"),
        require("@/assets/images/nokta_dashboard/4-(3).jpg"),
        require("@/assets/images/nokta_dashboard/4-(4).jpg"),
        require("@/assets/images/nokta_dashboard/4-(5).jpg"),
        require("@/assets/images/nokta_dashboard/4-(6).jpg"),
        require("@/assets/images/nokta_dashboard/4-(7).jpg"),
        require("@/assets/images/nokta_dashboard/4-(8).jpg"),
        require("@/assets/images/nokta_dashboard/4-(9).jpg"),
        require("@/assets/images/nokta_dashboard/4-(10).jpg"),
      ],
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    OpenDontMiss(index) {
      if (index == 0) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "BLPN",
          },
        });
      } else if (index == 1) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "BRLPL",
          },
        });
      } else if (index == 2) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "BRSTA",
          },
        });
      } else if (index == 3) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "EBG",
          },
        });
      } else if (index == 4) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "FSCK",
          },
        });
      } else if (index == 5) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "FSG",
          },
        });
      } else if (index == 6) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "FSLBY",
          },
        });
      } else if (index == 7) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "FSCM",
          },
        });
      } else if (index == 8) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "RANF",
          },
        });
      } else if (index == 9) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "RFP",
          },
        });
      }
    },
    OpenCollection(collection) {
      if (collection == 1) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "HSTD",
          },
        });
      } else if (collection == 2) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "HSMU",
          },
        });
      } else if (collection == 3) {
        this.$router.push({
          name: "nokta_market",
          query: {
            search: "HSFR",
          },
        });
      }
    },
    // RandomColor() {
    //   return Math.floor(Math.random() * 16777215).toString(16);
    // },
    GoLogin() {
      this.$router.push("/login").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    GoStore() {
      if (this.getNoktaMarketPermission) {
        this.$router.push("/nokta_market").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else if (this.getDesignMarketPermission) {
        this.$router.push("/design_market").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        this.$router.push("/machines").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    getNoktaMarketPermission() {
      return this.$store.getters.getNoktaMarketPermission;
    },
    getDesignMarketPermission() {
      return this.$store.getters.getDesignMarketPermission;
    },
  },
  components: {
    swiper,
    swiperSlide,
    // VueTyper,
  },
  beforeCreate() {
    if (localStorage.getItem("username") != null) {
      this.$store.dispatch("initLocalData");
    }
  },
};
</script>

<style scoped>
.vueTyperClass {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  padding: 100px;
  /* text-shadow: 0px 10px 20px black; */
}
.allCollectionsTitle {
  color: #c99e83;
  font-weight: 600;
  font-size: 1.4rem;
}
.customContainer {
  max-width: 1200px !important;
  width: 100%;
  padding: 10px;
}

.Image {
  width: 100%;
  height: auto;
}
.plates-block {
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;

  border: 2px solid black;
  opacity: 0.3;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-block {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabla {
  border-radius: 5px;
  background-color: #dedede;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 1);
}
</style>
